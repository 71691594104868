/* GLOBALS */

:root {
	--accent-right: #00c08b;
	--accent-left: #6188d7;
	--prim-dark: #272d2d;
	--prim-mid: #858585;
	--prim-light: #e7e7e7;
}

/* RESET CSS */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	font-family: 'Noto Sans', sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}
body > div:first-child {
	display: block !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
	body {
		-webkit-font-smoothing: subpixel-antialiased;
	}
}
ol, ul {
	list-style: none;
}
ol[type="a"] {
	list-style: lower-latin;
	margin-left: 40px;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, *:before, *:after {
	box-sizing: border-box;
}

a {
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	text-decoration: none;
}

img {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.wrapper-link {
	display: block;
}

.hidden {
	display: none;
}

.capitalize {
	text-transform: capitalize;
}

.capitalize.limit-width {
	text-transform: none;
}

.with-cursor {
	cursor: pointer;
}