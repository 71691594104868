.Notifications .DropdownMenuItem {
  padding: 0;
}

.Notification-item {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 15px 30px;
}

.Notification-item__type {
  line-height: 20px;
  max-width: 222px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Notification-item__type span {
  color: #00c08b;
}