.NestedLink {
  margin-bottom: 14px;
}

.NestedLink.last-in-list {
  margin-bottom: 30px;
}

.NestedLink__label {
  width: 100%;
  font-size: 14px;
  color: #272d2d;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  text-decoration: none;
  padding-right: 15px;
  transition: all .2s;
}

.link-in-one-line .NestedLink__label {
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NestedLink__label.NestedLink__label--active {
  color: #00c08b !important;
}

.NestedLink__label:hover {
  color: #6188d7;
}

.NestedLink__label::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  height: .075em;
  width: 10px;
  background: #858585;
}
