.chapters-button {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-image: url(../../../../../../assets/icons/list-active.svg);
}

.chapters-button[data-active=true] {
  background-image: url(../../../../../../assets/icons/list.svg);
}

.Chapters__content .play-list {
  padding: 0 20px;
}

.Chapters__content .play-list-item {
  margin: 0;
  width: 100%;
}

.Chapters__content .track-item {
  margin-left: 10px;
}