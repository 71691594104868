.cookie-container {
  max-width: 1040px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .cookie-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.CookiesPolicy__wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #171717;
  z-index: 2000;
  padding: 40px 0;
  font-family: inherit;
  line-height: 18px;
  clear: both;
}

@media screen and (max-width: 767px) {
  .CookiesPolicy__wrapper {
    overflow: auto;
    align-items: flex-start;
    max-height: 100%;
    padding-bottom: 16px;
  }
}

.CookiesPolicy__text {
  width: 70%;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  color: hsla(0,0%,100%,.7);
}

@media screen and (max-width: 767px) {
  .CookiesPolicy__text {
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
}

.CookiesPolicy__text a {
  color: #00c08b;
  transition: all 0.2s;
}

.CookiesPolicy__text a:hover {
  text-decoration: underline;
}

.CookiesPolicy__button {
  width: 30%;
  text-align: center;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  .CookiesPolicy__button {
    width: 100%;
    margin-top: 16px;
  }
}

.CookiesPolicy__cancel {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
}

.CookiesPolicy__cancel img {
  cursor: pointer;
}

.CookiesPolicy__text .btn-in-text {
  cursor: pointer;
  margin: 0 5px;
  color: #00c08b;
  transition: all 0.2s;
}

.CookiesPolicy__text .btn-in-text:hover {
  text-decoration: underline;
}

.preferences-list {
  margin-top: 20px;
}

.preferences-list-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.preferences-list-item input[type=checkbox] {
  display: none;
}

.preferences-list-item .checkbox-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 4px;
  flex: none;
  margin-top: 2px;
}

.preferences-list-item input[type=checkbox]:checked:disabled+.checkbox-icon {
  cursor: default;
  opacity: .5;
}

.preferences-list-item .checkbox-icon svg {
  display: none;
  width: 16px;
  height: 14px;
}

.preferences-list-item .checkbox-icon svg path {
  fill: #00c08b;
}

.preferences-list-item input[type=checkbox]:checked+.checkbox-icon svg {
  display: block;
}

.preferences-list-item .text {
  cursor: pointer;
  margin-left: 15px;
  line-height: 1.6;
  color: #00c08b;
  user-select: none;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .preferences-list-item .text {
    font-size: 12px;
    line-height: 1.5;
  }
}

.preferences-list-item input[type=checkbox]:checked:disabled ~ .text {
  cursor: default;
}

.Button__cookie-accept {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}

@media screen and (max-width: 767px) {
  .Button__cookie-accept {
    width: 100%;
  }
}

.Button__cookie-accept:hover {
  background-color: transparent;
}

.Button__cookie-secondary {
  cursor: pointer;
  display: none;
  margin: 10px auto 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  color: hsla(0,0%,100%,.7);
  transition: .2s;
  letter-spacing: 1px;
}

.Button__cookie-secondary.show {
  display: block;
}

.Button__cookie-secondary:hover {
  color: #fff;
  text-decoration: underline;
}