.Input {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(39, 45, 45, 0.5);
  font-size: 12px;
  color: #272d2d;
  text-align: left;
  outline: none !important;
  transition: all .2s ease-in-out;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.Input::-ms-clear {
  display: none;
  width : 0;
  height: 0;
}

.Input::-ms-reveal {
  display: none;
  width : 0;
  height: 0;
}

.Input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.Input__wrapper {
  position: relative;
  display: block;
}

.Input:focus {
  border-bottom: 1px solid rgba(39, 45, 45, 1);
}

.Input::placeholder {
  color: #858585;
  transition: all .2s ease-in-out;
}

.Input--invalid {
  border-bottom: 1px solid rgba(228,94,83, 0.5);
}

.Input--invalid::placeholder {
  color: rgba(228,94,83, 0.5);
}

.Input__show-password {
  width: 25px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
}

.Input__show-password i {
  width: 25px;
  height: 25px;
  position: relative;
  z-index: 1;
  background-image: url('../../../assets/icons/eye.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}

.Input__show-password i:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/icons/eye-hover.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.Input__show-password:hover i:before {
  opacity: 1;
}

.Input__show-password img {
  max-width: 100%;
}