.Loading {
  position: absolute;
  /* top: -20px;
  right: -10px;
  left: -10px;
  bottom: -25px; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s ease 0s, visibility 0s .2s;
}

.Loading[data-active="true"] {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s ease 0s, visibility 0s 0s;
}

.Loading__spinner {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top: 3px solid #00c08b;
  animation: spin .5s linear infinite;
}

.Loading__text {
  color: #bbb;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  animation: blink 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
