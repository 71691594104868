.bookmarks-button {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-image: url(../../../../../../assets/icons/audioplayer_bookmark_active.svg);
}

.bookmarks-button[data-active=true] {
  background-image: url(../../../../../../assets/icons/audioplayer_bookmark.svg);
}

.Bookmarks__content .mark-list {
  padding: 0 20px;
  width: 100%;
}

.Bookmarks__content .mark-list-item {
  margin: 0;
}

.Bookmarks__content .mark-item {
  margin-left: 10px;
}