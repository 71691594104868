.Notifications {
  position: relative;
  height: 32px;
  margin-right: 25px;
  padding: 0 35px;
  z-index: 1001;
}

.Notifications::before, .Notifications::after {
  content: '';
  height: 66px;
  width: 1px;
  background: #e7e7e7;
  position: absolute;
  top: -18px;
}

.Notifications::before {
  left: 0;
}

.Notifications::after {
  right: 0;
}

.Notifications__toggle {
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  animation: fadeIn .9s ease-in-out 0s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Notifications__toggle[data-unread="true"]::after {
  content: '';
  display: block;
  position: absolute;
  right: -6px;
  top: -6px;
  border-radius: 50%;
  background: red;
  width: 6px;
  height: 6px;
}

.Notifications__toggle-icon {
  max-height: 100%;
  background-image: url('../../../../../../assets/icons/notification.svg');
  width: 20px;
  height: 20px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  display: block;
  position: relative;
  z-index: 1;
}

.Notifications__toggle-icon:before {
  content: '';
  background-image: url('../../../../../../assets/icons/notification-hover.svg');
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.Notifications__toggle-icon:hover:before {
  opacity: 1;
}

.Notifications .DropdownMenu {
  top: calc(100% + 17px);
  min-width: 252px;
}

@media screen and (min-width: 1280px) {
  .Notifications .DropdownMenu {
    right: auto;
    left: 0;
  }
}

@media all and (min-width: 768px) {
  .Notifications .DropdownMenu {
    right: 0;
    left: auto;
  }
}

@media all and (max-width: 767px) {
  .Notifications::before, .Notifications::after {
    height: 54px;
    top: -11px;
  }
}

.Notifications__list {
  max-height: calc(4 * 88px);
  overflow-y: auto;
}
