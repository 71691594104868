.UploadBooksStatusBar {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 66px;
  top: 66px;
  width: calc(100% - 225px);
  left: 225px;
  background: rgb(252, 252, 252);
  border-bottom: 1px solid #ececec;
  flex-direction: row;
  z-index: 1;
}

@media screen and (max-width: 1279px) {
  .UploadBooksStatusBar {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .UploadBooksStatusBar {
    flex-direction: column;
    height: 86px;
    font-size: 14px;
    padding: 10px 30px;
    top: 54px;
    position: absolute;
  }
}

.UploadBooksStatusBar__button {
  text-transform: uppercase;
  color: rgb(0, 207, 142);
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  position: relative;
  padding-right: 30px;
}

.UploadBooksStatusBar__button figure{
  top: 40%;
}