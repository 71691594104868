.Collapsable {
  max-height: 1200px;
  overflow: hidden;
  transition: max-height .7s ease-in;
}

.Collapsable[data-collapsed="true"] {
  max-height: 0px;
  transition: max-height .5s ease-out;
}

.User__info.inSettings + .Collapsable {
  background-color: transparent !important;
}