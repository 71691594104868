.InnerLink {
  color: #00c08b;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  opacity: 1;
  padding-right: 40px;
  position: relative;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}

.InnerLink:hover {
  color: #6188d7;
}

.InnerLink__arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  pointer-events: none;
  height: 7px;
}

.InnerLink__arrow i {
  position: relative;
  z-index: 1;
  display: block;
  width: 22px;
  height: 7px;
  background-image: url('../../../assets/icons/arrow.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.InnerLink__arrow i:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all .2s ease-in-out;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/icons/arrow-hover.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.InnerLink:hover i:before {
  opacity: 1;
}

.InnerLink__arrow img {
  display: block;
  max-width: 100%;
}

@media screen and (max-width: 1279px) {
  .InnerLink {
    font-size: 12px;
  }
}
