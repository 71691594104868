.AppLinks {
  width: 100%;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

.AppLinks__title {
  font-size: 14px;
  font-weight: 500;
  color: #00c08b;
  margin-bottom: 14px;
  text-align: center;
  width: 100%;
}
