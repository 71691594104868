.DropdownMenuItem {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 30px;
  font-size: 14px;
  font-weight: 400;
  color: #272d2d;
  word-break: break-word;
}

.HeaderMenu__Sidebar .DropdownMenuItem-subitems {
  padding-top: 0 !important;
  padding-left: 15px !important;
}

.HeaderMenu__Sidebar .DropdownMenuItem-subitems .NavigationLink .NavigationLink__label {
  border: none !important;
  height: 30px;
  line-height: 30px;
}

.HeaderMenu__Sidebar .DropdownMenuItem-subitems .NavigationLink {
  border: none !important;
  min-height: 30px;
}

.DropdownMenuItem[data-action="true"] {
  cursor: pointer;
  transition: all .2s ease-in-out;
  background: #fff;
  padding: 0;
}

.DropdownMenuItem[data-action="true"] > * {
  padding: 15px 30px;
}

.DropdownMenuItem a:hover {
  color: #6188d7;
}

.LastRead__cover .DropdownMenuItem:hover,
.BookItem .DropdownMenuItem:hover,
.BooksGallery__item .DropdownMenuItem:hover,
.User > .DropdownMenu > .DropdownMenuItem:hover
{
  color: #6188d7;
}

.DropdownMenuItem[data-action="true"]:active {
  color: #00c08b;
}

.DropdownMenuItem:last-of-type {
  border: none;
}

.DropdownMenuItem .empty-message {
  color: #858585;
  display: block;
  text-align: center;
  padding: 15px;
}

.DropdownMenuItem__inner {
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #e7e7e7;
}

.DropdownMenuItem__inner > li {
  position: relative;
  cursor: pointer;
  line-height: 17px;
  /*word-break: break-all;*/
  padding: 5px 30px;
  font-size: 14px;
  font-weight: 400;
  color: #272d2d;
  transition: all .2s ease-in-out;
}

.DropdownMenuItem__inner > li:hover {
  color: #6188d7;
}

.DropdownMenuItem__inner > li:active {
  color: #00c08b;
}
