.AppLink {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid #00c08b;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;
}

.AppLink:first-of-type {
  margin-right: 8px;
}

.AppLink__icon {
  height: 23px;
  width: 23px;
}

.AppLink__icon i {
  position: relative;
  z-index: 1;
  width: 21px;
  height: 21px;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}

.AppLink__icon i:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  transition: all .2s ease-in-out;
}

.AppLink:hover {
  border-color: #6188d7 !important;
}

.AppLink:hover i:before {
  opacity: 1;
}

.AppLink:active {
  border-color: #00c08b !important;
}

.AppLink:active i:before {
  opacity: 0;
}

.AppLink__icon .Android {
  width: 23px;
  height: 23px;
  background-image: url('../../../../../../../../assets/icons/android.svg');
}

.AppLink__icon .Android:before {
  background-image: url('../../../../../../../../assets/icons/android-hover.svg');
}

.AppLink__icon .iOS {
  background-image: url('../../../../../../../../assets/icons/ios.svg');
}

.AppLink__icon .iOS:before {
  background-image: url('../../../../../../../../assets/icons/ios-hover.svg');
}

.AppLink__icon img {
  max-width: 100%;
}
