.Search {
  position: relative;
  flex-grow: 1;
  height: 32px;
  margin-right: 25px;
  width: 0;
}

.Search__toggle {
  outline: none;
  border: none;
  box-shadow: none;
  display: inline-block;
  position: absolute;
  background: transparent;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.Search__toggle--cancel {
  width: 12px;
  height: 12px;
}

.Search__toggle-icon {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-image: url('../../../../../../assets/icons/search.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}

.Search__toggle-icon.cancel {
  background-image: url('../../../../../../assets/icons/cancel.svg');
  width: 12px;
  height: 12px;
}

.Search__toggle-icon:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all .3s ease-in-out;
  background-image: url('../../../../../../assets/icons/search-hover.svg');
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.Search__toggle-icon.cancel:before {
  background-image: url('../../../../../../assets/icons/cancel-hover.svg');
}

.Search__toggle:hover i:before {
  opacity: 1;
}

.Search__input-wrapper[data-show="true"] + .Search__toggle {
  animation: fadeInCancel .9s ease-in-out 0s;
}

@keyframes fadeInCancel {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Search__input-wrapper[data-show="false"] + .Search__toggle {
  animation: fadeInSearch .9s ease-in-out 0s;
}

@keyframes fadeInSearch {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Search__input-wrapper {
  visibility: hidden;
  opacity: 0;
  transform: scaleX(0);
  backface-visibility: hidden;
  transform-origin: right;
  transition: opacity .5s .4s, transform .5s .4s, visibility 0s .9s;
}

.Search__input-wrapper[data-show="true"] {
  visibility: visible;
  transform: scaleX(1);
  opacity: 1;
  transition: opacity .5s 0s, transform .5s 0s, visibility 0s 0s;
}

.Search__input-wrapper .Input {
  height: 32px;
  padding-right: 30px;
  line-height: 32px;
  background: transparent;
}

.Search__input-wrapper .Input::placeholder {
  color: transparent;
  transition: color .3s ease .2s;
}

.Search__input-wrapper[data-show="true"] .Input::placeholder {
  color: #858585;
  transition: color .3s ease .5s;
}

.Search .DropdownMenu {
  width: 100%;
  top: calc(100% + 17px);
}

.Search .DropdownMenuItem {
  padding: 0;
}

@media all and (max-width: 767px) {
  .Search .DropdownMenu {
    top: calc(100% + 7px);
    /* width: 300px; */
  }
  .Search[data-active="false"] .DropdownMenu {
    visibility: false;
    transition: all 0s 0s;
  }
  .Search__input-wrapper {
    transition: all 0s 0s;
  }
  .Search[data-active="true"] {
    position: absolute;
    right: -15px;
    height: 52px;
    width: calc(100vw - 20px);
    z-index: 5000;
    background: #fff;
  }
  .Search[data-active="true"] .Search__input-wrapper {
    margin-top: 10px;
  }
}
