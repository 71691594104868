.book-info-button {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-image: url(../../../../../../assets/icons/audioplayer_info_active.svg);
}

.book-info-button[data-active=true] {
  background-image: url(../../../../../../assets/icons/audioplayer_info.svg);
}

.BookInfo__content .line {
  border-bottom: 1px solid #e7e7e7;
}

.BookInfo__content .line:last-child {
  border-bottom-width: 0 !important;
}

.inFooter .BookInfo__list {
  padding: 0 20px;
}

.onPage .BookInfo__list .line {
  margin: 0 10%;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 10%;
}

.onPage .BookInfo__list .line:last-child {
  border-bottom-width: 0;
}

.line .line-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 15px 0 5px;
}

.onPage .line .line-title {
  margin-top: 0;
}

.line .line-text {
  display: block;
  word-break: break-all;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin-bottom: 15px;
}

.onPage .line .line-text {
  margin-bottom: 0;
}

.line .line-text b {
  font-weight: bold;
}