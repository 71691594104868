.Bookmark__adding {
    position: absolute;
    right: 15.5%;
}

.audioBook.inFooter .Bookmark__adding {
    position: relative;
    right: 0;
}

@media screen and (max-width: 767px) {
    .Bookmark__adding {
        right: 4.3%;
    }
    .audioBook.inFooter .Bookmark__adding {
        right: 0;
    }
}

.AudioBook__dropdown .Bookmark__adding__header {
    background-image: none;
    padding: 0 11px;
    height: 38px;
}

.AudioBook__dropdown .Bookmark__adding__header-text {
    color: #858585;
    white-space: nowrap;
    font-weight: bold;
}

.Bookmark__adding .DropdownMenu {
    min-width: 260px;
}

.Bookmark__adding__buttons {
    text-align: right;
    margin-top: 10px;
}

.Bookmark__adding .Button {
    background: none;
    box-shadow: none;
    color: #00c08b;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.Bookmark__adding .Button:hover {
    color: #6188d7;
    box-shadow: none;
    background-image: none;
}

.Bookmark__adding .Button:hover:before {
    display: none;
}

.Bookmark__adding__content {
    padding: 0 11px 11px;
}