.SearchResults .wrapper {
  display: flex;
}
.SearchResults > div {
  max-height: calc(100vh - 86px);
}

@media all and (max-width: 767px) {
  .SearchResults .wrapper {
    flex-direction: column;
  }
}

.SearchResults .title {
  text-transform: uppercase;
  padding-left: 30px;
  background: #e7e7e7;
  padding: 15px 0 15px 30px;
  border-right: 1px solid white;
  font-weight: 600;
}

@media all and (max-width: 767px) {
  .SearchResults .title {
    border-right: none;
  }
}

.SearchResults .results-list {
  margin: 10px 0 10px 30px;
}

/* .SearchResults .results-list:empty {
  margin: 0;
} */

.SearchResults .results-item {
  padding: 7px 0;
}

.SearchResults .results-item a {
  transition: all .2s ease-in-out;
}

.SearchResults .results-item a:hover {
  color: #6188d7;
}
.SearchResults .results-item a:active {
  color: #00c08b;
}

.SearchResults .results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #e7e7e7;
  max-width: 50%;
}
@media all and (max-width: 767px) {
  .SearchResults .results-container {
    width: 100%;
    border-right: none;
    max-width: 100%;
  }
}

.SearchResults .books {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media all and (max-width: 767px) {
  .SearchResults .books {
    width: 100%;
  }
}

.SearchResults .books .title {
  border-right: none;
  border-left: 1px solid white;
}

@media all and (max-width: 767px) {
  .SearchResults .books .title {
    border-left: none;
  }
}

.SearchResults .results-book-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.SearchResults .results-book-wrapper__book-cover {
  width: 55px;
  height: 75px;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
  background-position: center center;
  background-repeat: no-repeat;
}

.SearchResults .results-book-wrapper__book-cover.isAudioBook {
  height: 55px;
  background-color: rgba(97, 136, 215, 0.36);
  background-size: contain !important;
  position: relative;
}

.SearchResults .results-book-wrapper__book-cover.isAudioBook i {
  background-image: url(../../../../../../../../assets/icons/headphone.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.SearchResults .results-book-wrapper__book-title {
  margin-left: 20px;
  width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SearchResults .results-wrapper-link {
  display: block;
  cursor: default;
}

.SearchResults .results-wrapper-link.with-cursor {
  cursor: pointer;
}

.SearchResults .results-wrapper-link.with-cursor .results-book-wrapper__book-cover {
  position: relative;
}

.SearchResults .results-wrapper-link.with-cursor .results-book-wrapper__book-cover:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.6);
  opacity: 0;
  transition: .2s ease all;
  z-index: 1;
}

.SearchResults .results-wrapper-link.with-cursor .results-book-wrapper__book-cover:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-image: url(../../../../../../../../assets/icons/hover-book.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  transition: .2s ease all;
  z-index: 2;
}

.SearchResults .results-wrapper-link.with-cursor .results-book-wrapper__book-cover.isAudioBook:after {
  background-image: url(../../../../../../../../assets/icons/hover-audiobook.svg);
  width: 30px;
  height: 30px;
}

.SearchResults .results-wrapper-link.with-cursor:hover .results-book-wrapper__book-cover:before,
.SearchResults .results-wrapper-link.with-cursor:hover .results-book-wrapper__book-cover:after {
  opacity: 1;
}

@media all and (max-width: 767px) {
  .SearchResults .book-name {
    margin-right: 20px;
  }

  .SearchResults .results-wrapper-link.with-cursor:hover .results-book-wrapper__book-cover:before,
  .SearchResults .results-wrapper-link.with-cursor:hover .results-book-wrapper__book-cover:after {
    opacity: 0;
  }
}
