.Button {
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 3em;
  border: none;
  outline: none !important;
  box-shadow: none;
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;
  transform: translateZ(0px);
}

.Button {
  box-shadow: 0 5px 20px 0 rgba(97, 136, 215, 0.5);
  background-size: 100%;
  background-image: linear-gradient(to right, #6188d7, #00c08b);
  display: inline-block;
  position: relative;
  z-index: 1;
}

.Button:before
{
  display: block;
  border-radius: inherit;
  background-image: linear-gradient(to right, #00c08d, #6188d7);
  content: '';
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  transition: opacity 0.45s;
  cursor: pointer;
}

.Button:hover:before
{
  opacity: 1;
}

.Button--social {
  width: 100%;
  transition: all .2s ease-in-out;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 20px 0 rgba(97, 134, 215, 0.5);
}

.Wrap__facebook {
  position: relative;
}

.Wrap__facebook .Button--social[social-type="facebook"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.Wrap__facebook .fb-login-button {
  z-index: 1;
}

.Button--social img {
  height: 25px;
  margin-left: 12px;
  margin-right: 8px;
}

.Button--social span {
  width: calc(100% - 45px);
  padding-right: 12px;
  text-align: center;
}

.Button--social:hover {
  box-shadow: 0 5px 25px 0 rgba(97, 134, 215, 0.7);
}
.Button--social:hover:before {
  display: none;
}

.Button--social[social-type="facebook"],
.fb-login-button {
  height: 40px !important;
  background: #395185;
  text-transform: initial;
  font-size: 14px;
}

.fb-login-button > span {
  vertical-align: top !important;
}

.Button--social[social-type="google-plus"] {
  height: 40px !important;
  background: #fff;
  color: #757575;
  font-size: 15px;
  letter-spacing: .25px;
  font-weight: 600;
}