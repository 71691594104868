.audioBook {
  display: flex;
  flex-direction: row;
}

.audioBook .cover-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 10%;
  align-items: center;
  margin-bottom: 70px;
  position: relative;
}

.Audiobook__no-cover {
  width: 235px;
  height: 235px;
  background-color: rgba(97, 136, 215, 0.36);
  margin-bottom: 0;
}

.Audiobook__no-cover .EmptyAudioBookCover {
  background-image: url(../../../../assets/icons/headphone.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 95px;
  height: 95px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .Audiobook__no-cover {
    width: 200px;
    height: 200px;
  }
  .Audiobook__no-cover .EmptyAudioBookCover {
    width: 80px;
    height: 80px;
  }
}

.audioBook .play-list-wrapper {
  width: 40%;
  margin-right: 10%;
}

.audioBook .cover-img {
  width: 300px;
  height: 300px;
  box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
}

.audioBook.inFooter .cover-img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .audioBook .cover-img {
    width: 200px;
    height: 200px;
  }
}

.audioBook .cover-img h1 {
  margin: 0 auto;
  align-items: center;
}

.audioBook .play-list {
} 

.audioBook .play-list-item,
.audioBook .mark-list-item {
  margin: 0 10%;
  padding: 10px 10%;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
}

.audioBook .mark-list-item {
  padding: 10px 16% 10px 10%;
}

.audioBook.inFooter .mark-list-item {
  padding-right: 25%;
}

.audioBook.inFooter .play-list-item,
.audioBook.inFooter .mark-list-item {
  margin: 0;
}

.audioBook .play-list-item:last-child,
.audioBook .mark-list-item:last-child {
  border-bottom-width: 0;
}

.audioBook .mark-list-item:before {
  content: '';
  position: absolute;
  display:block;
  height: 20px;
  width: 20px;
  left: 3%;
  top: 0;
    bottom: 0;
    margin: auto;
  background-image: url('../../../../assets/icons/audioplayer_bookmark.svg');
  background-size: cover;
}

.audioBook .mark-buttons {
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  display: flex;
  align-items: center;
}

.audioBook .remove-markdown,
.audioBook .edit-markdown {
  content: '';
  cursor: pointer;
  display:block;
  height: 20px;
  width: 20px;
  background-size: cover;
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0 5px;
}

.audioBook .remove-markdown {
    background-image: url('../../../../assets/icons/ic_delete.svg');
}

.audioBook .edit-markdown {
  background-image: url('../../../../assets/icons/ic_edit.svg');
}

@media screen and (max-width: 767px) {
  .audioBook .play-list-item,
  .audioBook .mark-list-item {
    margin: 0 4%;  
  }
}

.audioBook .play-list-item.active::before {
  content: '';
  position: absolute;
  display:block;
  height: 20px;
  width: 20px;
  background-size: 30px;
  left: 3%;
  top: 0;
  bottom: 0;
  margin: auto;
  background: center center no-repeat url(../../../../assets/icons/ic_play.svg);
  background-size: contain;
}

.audioBook .track-item,
.audioBook .mark-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.audioBook .track-name
 {
  font-size: 14px;
  font-weight: 500;
  color: #272d2d;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.3;
  width: 100%;
}

.audioBook .mark-played {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100%;
}

.audioBook .track-duration,
.audioBook .mark-date {
  font-size: 12px;
  font-weight: 400;
  color: #858585;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.3;
  width: 100%;
}
.audioBook .mark-note {
  font-size: 12px;
  margin-top: 8px;
  word-break: break-all;
  width: 100%;
}
.audioBook .react-player {
  height: 0;
  width: 0;
  display: none;
}

.audioBook .controls-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
}

.audioBook .controls-name h1 {
  display: block;
  padding: 15px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0;
  font-size: 18px;
}


.audioBook .controls-seeker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  width: 68%;
  height: 20px;
  display: block;
}

@media screen and (max-width: 767px) {
  .audioBook .controls-seeker {
    width: 90%;
  }
}

.audioBook .controls-seeker:focus {
  box-shadow: none;
  outline: none;
}

.audioBook .controls-seeker::-moz-focus-outer {
  border: 0;
}

.audioBook .controls-seeker::-webkit-slider-runnable-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-seeker::-webkit-slider-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
          appearance: none;
  border-radius:50%;
  position: relative;
  top: -8px;
  border: none;
}

.audioBook .controls-seeker::-moz-range-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
  outline: none;
}

.audioBook .controls-seeker::-moz-range-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  border-radius:50%;
  position: relative;
  top: -8px;
  outline: none;
  border: none;
}

.audioBook .controls-seeker::-moz-range-progress {
  background: linear-gradient(to right, #6188d7, #00c08b);
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-seeker::-ms-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-seeker::-ms-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  border-radius:50%;
  position: relative;
  top: -7px;
}

.audioBook .controls-seeker::-ms-fill-lower {
  background: linear-gradient(to right, #6188d7, #00c08b);
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-seeker::-ms-fill-upper {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .buttons-wrapper {
  margin-top: 30px;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.audioBook .player-button {
  width: 20px;
  height: 20px;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: none !important;
}

.player-button.prev-button {
  background: center center no-repeat url('../../../../assets/icons/audioplayer_previous.svg');
  background-size: cover;
}

.player-button.play-button {
  background: center center no-repeat url('../../../../assets/icons/audioplayer_play.svg');
  background-size: cover;
}

.player-button.pouse-button {
  background: center center no-repeat url('../../../../assets/icons/audioplayer_pause.svg');
  background-size: cover;
}

.player-button.next-button  {
  background: center center no-repeat url('../../../../assets/icons/audioplayer_next.svg');
  background-size: cover;
}

.player-button.add-to-bookmark {
  background: center center no-repeat url('../../../../assets/icons/audioplayer_bookmark_add_active.svg');
  background-size: cover;
}

.player-button.muted-button {
  background: center center no-repeat url('../../../../assets/icons/muted.svg');
  background-size: cover;
}
.player-button.volume-button {
  background: center center no-repeat url('../../../../assets/icons/volume.svg');
  background-size: cover;
}

.onPage .player-button.volume-button,
.onPage .player-button.muted-button {
  margin-right: 15px;
  flex: none;
}

.player-button.stop-button {
  background: center center no-repeat url('../../../../assets/icons/stop.svg');
  background-size: cover;
}

.player-button.expand-button {
  background: center center no-repeat url('../../../../assets/icons/expand.svg');
  background-size: cover;
}

.audioBook .controls-volume {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 25px 0 0;
  width: 68%;
  height: 20px;
  display: block;
  padding: 0 25px;
  position: relative;
}


@media screen and (max-width: 767px) {
  .audioBook .controls-volume {
    width: 90%;
  }
}

.audioBook .controls-volume::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  right: -3px;
  top: -3px;
  cursor: default;
  background: center center no-repeat url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' width='20' height='20'><g><path fill='#00b284' d='M28.0748663,36.3636364 L0,36.3636364 L0,65.9090909 L30.4812834,65.9090909 L54.5454545,88.6363636 L54.5454545,11.3636364 L28.0748663,36.3636364 Z'></path><path fill='#00b284' d='M84.6032335,82.4592965 C94.5340754,74.7600841 100.468182,62.9599381 100.468182,50.1791986 C100.468182,38.1777252 95.2347685,27.0146095 86.3177905,19.2913999 L80.3660059,26.1631456 C87.313543,32.1805749 91.3772727,40.8487007 91.3772727,50.1791986 C91.3772727,60.1143215 86.7696647,69.2766862 79.0331302,75.2746895 L84.6032335,82.4592965 L84.6032335,82.4592965 Z'></path><path fill='#00b284' d='M68.6941426,71.5946428 C75.48494,66.3298533 79.5454545,58.2554001 79.5454545,49.5119787 C79.5454545,41.3018627 75.9644339,33.663378 69.8670756,28.382309 L63.9152911,35.2540546 C68.0432084,38.8293434 70.4545455,43.9728382 70.4545455,49.5119787 C70.4545455,55.4097835 67.7205293,60.8464555 63.1240393,64.4100358 L68.6941426,71.5946428 L68.6941426,71.5946428 Z'></path></g></svg>")
}

.audioBook .controls-volume::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: -3px;
  top: -3px;
  cursor: default;
  background: center center no-repeat url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' width='20' height='20'><g><path fill='#00b284' d='M28.0748663,36.3636364 L0,36.3636364 L0,65.9090909 L30.4812834,65.9090909 L54.5454545,88.6363636 L54.5454545,11.3636364 L28.0748663,36.3636364 Z'></path><path fill='#00b284' d='M68.6941426,71.5946428 C75.48494,66.3298533 79.5454545,58.2554001 79.5454545,49.5119787 C79.5454545,41.3018627 75.9644339,33.663378 69.8670756,28.382309 L63.9152911,35.2540546 C68.0432084,38.8293434 70.4545455,43.9728382 70.4545455,49.5119787 C70.4545455,55.4097835 67.7205293,60.8464555 63.1240393,64.4100358 L68.6941426,71.5946428 L68.6941426,71.5946428 Z'></path></g></svg>")
}


.audioBook .controls-volume::-webkit-slider-runnable-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-volume::-webkit-slider-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
          appearance: none;
  border-radius:50%;
  position: relative;
  top: -7px;
  border: none;
}

.audioBook .controls-volume::-moz-focus-outer {
  border: 0;
}


.audioBook .controls-volume::-moz-range-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
  outline: none;
}

.audioBook .controls-volume::-moz-range-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  border-radius:50%;
  position: relative;
  top: -7px;
  outline: none;
  border: none;
}

.audioBook .controls-volume::-moz-range-progress {
  background: linear-gradient(to right, #6188d7, #00c08b);
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}


.audioBook .controls-volume::-ms-track {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-volume::-ms-thumb {
  background: #00b284;
  height: 20px;
  width: 20px;
  border-radius:50%;
  position: relative;
  top: -7px;
}

.audioBook .controls-volume::-ms-fill-lower {
  background: linear-gradient(to right, #6188d7, #00c08b);
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .controls-volume::-ms-fill-upper {
  background: #e6e6e6;
  border-radius: 10px;
  height: 5px;
  position: relative;
  content: '';
}

.audioBook .react-tabs__tab-list {
  display: flex;
  justify-content: center;
  margin: 0 10%;
  margin-bottom: 20px;
}

.audioBook .react-tabs__tab:nth-child(1) {
  background-image: url('../../../../assets/icons/audioplayer_info.svg');
}

.audioBook .react-tabs__tab.react-tabs__tab--selected:nth-child(1) {
  background-image: url('../../../../assets/icons/audioplayer_info_active.svg');
}

.audioBook .react-tabs__tab:nth-child(2) {
  background-image: url("../../../../assets/icons/list.svg");
}

.audioBook .react-tabs__tab.react-tabs__tab--selected:nth-child(2) {
  background-image: url("../../../../assets/icons/list-active.svg");
}

.audioBook .react-tabs__tab:nth-child(3) {
  background-image: url('../../../../assets/icons/audioplayer_bookmark.svg');
}

.audioBook .react-tabs__tab.react-tabs__tab--selected:nth-child(3) {
  background-image: url('../../../../assets/icons/audioplayer_bookmark_active.svg');
}

.audioBook .react-tabs__tab.react-tabs__tab--selected {
  position: relative;
  border-bottom: 2px solid #00b284;
}

.audioBook .react-tabs__tab {
  cursor: pointer;
  outline: none;
  width: 33.333%;
  height: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e7e7e7;
  background-position: center center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.audioBook .audiobook-info {
  margin: 0 10%;
  padding: 10px 10%;
  border-bottom: 1px solid #e7e7e7;
}

.audioBook .audiobook-info h3 {
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 14px;
}

.audioBook .played-duration {
  margin: 20px 0;
  width: 68%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.played-duration-times {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
}

.audioBook.inFooter .played-duration-times {
  margin: 0;
}

.played-duration .input-range {
  height: 20px;
  margin: 0;
}

.audioBook.inFooter .played-duration .input-range {
  height: 12px;
}

.react-tabs__tab-panel {
  display: none;
  width: 100%;
  height: 60vh;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  display: block;
}

@media screen and (max-width: 767px) {
  .audioBook .played-duration {
    width: 90%;
  }
  .react-tabs__tab-panel {
    height: 300px;
  }
}

@media screen and (max-width: 1280px) {
  .audioBook .cover-wrapper {
    width: 50%;
    margin-left: 0;
  }
  
  .audioBook .play-list-wrapper {
    width: 50%;
    margin-right: 0;
  }
}


@media screen and (max-width: 767px) {
  .audioBook {
    flex-direction: column;
  }
  .audioBook .cover-wrapper {
    width: 100%;
  }
  
  .audioBook .play-list-wrapper {
    width: 100%;
  }
}

.bookmark_notifications {
  position: fixed;
  bottom: 76px;
  right: 20px;
  left: auto;
  z-index: 2;
  width: 230px;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bookmark_notification {
  background-color: rgba(0,0,0,.6);
  color: #fff;
  padding: 7px 10px;
  line-height: 1.2;
  border-radius: 10px;
  transition: .3s ease opacity;
  font-size: 14px;
  margin-top: 10px;
  animation-name: fadeIn;
  animation-duration: .4s;
  animation-timing-function: ease-in-out;
}

.audioBook.inFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 66px;
  left: 0;
  border-top: 1px solid #ececec;
  padding-left: 225px;
  display: flex;
  align-items: center;
  z-index: 21;
}

.audioBook.inFooter .cover-wrapper {
  margin: 0;
  width: 100%;
  flex-direction: row;
}

.audioBook.inFooter .cover-wrapper .cover {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 30px;
  flex: none;
}

@media screen and (max-width: 968px) {
  .audioBook.inFooter .cover-wrapper .cover {
    display: none;
  }
}

.audioBook.inFooter .Audiobook__no-cover {
  width: 100%;
  height: 100%;
}

.audioBook.inFooter .Audiobook__no-cover .EmptyAudioBookCover {
  width: 20px;
  height: 20px;
}

.audioBook.inFooter .raect-player {
  display: none;
}

.audioBook.inFooter .controls-name {
  text-align: left;
  padding: 0 20px;
  width: auto;
  flex: 1;
  min-width: 340px;
}

.audioBook.inFooter .controls-name h1 {
  font-size: 14px;
  padding: 0;
}

.audioBook.inFooter .played-duration {
  margin: 6px 0 0;
  width: 100%;
}

.audioBook .played-duration .capitalize {
  font-size: 12px;
  white-space: nowrap;
}

.audioBook.inFooter .buttons-wrapper {
  margin-top: 0;
  padding-left: 5%;
  width: 470px;
  justify-content: unset;
}

.audioBook.inFooter .other-buttons-wrapper {
  display: flex;
  margin-left: auto;
  padding-left: 30px;
}

.audioBook.inFooter .player-button {
  margin: 0 10px;
  display: block;
  flex: none;
}

.audioBook .volume-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.audioBook .volume-wrapper .input-range {
  width: 150px;
  margin-left: 0;
  margin-right: 10px;
}

.audioBook.onPage .volume-wrapper {
  margin: 50px 0 0;
  width: 50%;
}

.audioBook.onPage .volume-wrapper .input-range {
  width: 100%;
}

@media screen and (max-width: 1279px) {
  .audioBook.inFooter {
    padding-left: 0;
  }
}

.input-range {
  width: 100%;
  position: relative;
  margin: 0 20px;
}

.input-range__label {
  display: none;
}

.input-range__track--background {
  height: 4px;
  background-color: #e7e7e7;
  border-radius: 1.5px;
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
}

.input-range__track--active {
  height: 4px;
  border-radius: 1.5px;
  background-image: linear-gradient(to right, #6188d7, #00c08d);
}

.input-range__slider {
  position: absolute;
  top: 50%;
  width: 14px;
  height: 14px;
  background-color: #00c08b;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -9px;
  margin-left: -6px;
  outline: none;
}

.AudioBook__dropdown {
  position: relative;
}

.AudioBook__dropdown .DropdownMenu {
  top: auto;
  bottom: 44px;
}

.AudioBook__dropdown .Dropdown__header {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 15px 0 22px;
  background-image: linear-gradient(to right, #6188d7, #00c08d);
  color: #fff;
  font-size: 14px;
}

.AudioBook__dropdown .DropdownMenu > div:last-child > div > div:last-child > div,
.audioBook .react-tabs__tab-panel > div > div:last-child > div
{
  width: 2px !important;
  transition: width .3s ease, margin-left .3s ease;
  margin-left: 4px;
}

@media screen and (max-width: 1279px) {
  .audioBook.inFooter .buttons-wrapper {
    padding-left: 1vw;
  }
  .audioBook.inFooter .volume-wrapper .input-range {
    width: 100px;
  }
}

#edit-audiobook-note {
  box-shadow: none;
  background: transparent;
}