.HeaderMenu {
  position: relative;
  height: 32px;
  padding-left: 40px;
  z-index: 1001;
  margin-left: auto;
}

@media screen and (min-width: 1280px) {
  .HeaderMenu {
    display: none;
  }
}

.HeaderMenu__toggle {
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  position: absolute;
  left: calc(50% + 10px);
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  animation: fadeIn .9s ease-in-out 0s;
  z-index: 1000;
}

.HeaderMenu__toggle-bar {
  width: 20px;
  height: 1px;
  background: #00c08b;
  position: relative;
  top: 0;
  margin: 0 auto;
}

.HeaderMenu__toggle-bar:not(:last-of-type) {
  margin-bottom: 5px;
}

.HeaderMenu .DropdownMenu {
  top: -20px;
  right: -20px;
  background: transparent;
  box-shadow: none;
}

.HeaderMenu__toggle[data-active="false"] .HeaderMenu__toggle-bar:nth-child(1),
.HeaderMenu__toggle[data-active="false"] .HeaderMenu__toggle-bar:nth-child(3) {
  transition: top .2s ease-in .2s, transform .2s ease 0s;
  top: 0;
  transform: rotate(0deg);
}

.HeaderMenu__toggle[data-active="false"] .HeaderMenu__toggle-bar:nth-child(2) {
  visibility: visible;
  transform: scaleX(1);
  transition: transform .5s ease .2s, visibility 0s linear 0s;
}

.HeaderMenu__toggle[data-active="true"] .HeaderMenu__toggle-bar:nth-child(2) {
  visibility: none;
  transform: scaleX(0);
  transition: transform .5s ease 0s, visibility 0s linear .5s;
}

.HeaderMenu__toggle[data-active="true"] .HeaderMenu__toggle-bar:nth-child(1) {
  transform: rotate(135deg);
  transition: top .2s ease-in 0s, transform .2s ease .2s;
  top: 6px;
}

.HeaderMenu__toggle[data-active="true"] .HeaderMenu__toggle-bar:nth-child(3) {
  transform: rotate(-135deg);
  transition: top .2s ease-in 0s, transform .2s ease .2s;
  top: -6px;
}

.HeaderMenu__Sidebar .HeaderMenu__Sidebar-shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}

.HeaderMenu__Sidebar .Sidebar {
  display: flex;
  width: 320px;
  height: 90vh;
  border-right: none;
  box-shadow: none;
  padding: 68px 0 0;
  z-index: 100;
  transform: translateX(100%);
  transition: transform .3s ease-out 0s;
  transform-origin: top;
  box-shadow: 0 5px 10px 0 rgba(133, 133, 133, 0.5);
}

.HeaderMenu__Sidebar .Sidebar .NavigationLink {
  width: 100%;
}

@media screen and (max-width: 1279px) {
  .HeaderMenu__Sidebar .Sidebar__title {
    display: none;
  }
  .HeaderMenu__Sidebar .Sidebar__content.hidden {
    display: none;
  }
}

.HeaderMenu__Sidebar[data-active="true"] .Sidebar {
  transform: translateX(0%);
}

.HeaderMenu__Sidebar .Sidebar__content {
  padding: 0;
  order: 1;
  margin: 0;
}

.HeaderMenu__Sidebar .Sidebar[data-sidebar-type="settings"] .Sidebar__title {
  margin-bottom: 30px;
  padding-left: 30px;
}

.HeaderMenu__Sidebar .Sidebar[data-sidebar-type="settings"] .NavigationLink {
  margin: 0;
}

.HeaderMenu__Sidebar .Sidebar[data-sidebar-type="settings"] .NavigationLink:last-of-type {
  border-bottom: 1px solid #ececec;
}

.HeaderMenu__Sidebar .Sidebar__call-to-action {
  order: 3;
}

.HeaderMenu__Sidebar .Sidebar__footer {
  padding: 20px 0;
  order: 2;
}

.HeaderMenu__Sidebar .NavigationLink {
  margin: 0;
  left: auto;
  padding-left: 0;
  min-height: 50px;
}

.HeaderMenu__Sidebar .NavigationLink .NavigationLink__label {
  display: block;
  border-top: 1px solid #e7e7e7;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  border-bottom: 1px solid transparent;
  margin-bottom: 0;
}

.HeaderMenu__Sidebar .NavigationLink[data-collapsable="true"] .NavigationLink__label {
  padding-right: 35px;
}

.HeaderMenu__Sidebar .NavigationLink[data-collapsable="true"] .NavigationLink__label::after {
  top: 22px;
  right: 30px;
}

.HeaderMenu__Sidebar .NavigationLink[data-collapsable="true"][data-collapsed="false"] .NavigationLink__label {
  background: rgba(231, 231, 231, .3);
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 15px;
}

.HeaderMenu__Sidebar .NestedLink {
  padding: 0 0 0 30px;
  margin-bottom: 15px !important;
  height: 18px;
  line-height: 18px;
}

@media screen and (max-width: 1279px) {
  .HeaderMenu__Sidebar .NestedLink {
    height: auto;
    vertical-align: top;
  }
}