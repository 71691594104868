.User {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  animation: fadeIn .9s ease-in-out 0s;
  z-index: 1001;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.User__username {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding-right: 25px;
  cursor: pointer;
  color: #272d2d;
  position: relative;
  width: calc(100% - 60px);
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateX(2px);
  transition: all .2s;
  height: 26px;
  display: flex;
  align-items: center;
}

.User__username::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 2px;
  transform: rotate(45deg);
  top: 0;
  bottom: 3px;
  margin: auto;
  pointer-events: none;
}

.User__username.hide-arrow::after {
  display: none;
}

.User[data-active="true"] .User__username::after {
  transform: rotate(225deg);
  bottom: 0;
}


.User__avatar {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.User__avatar-ph {
  color: #272d2d;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: all .2s;
}

.User__avatar-wrapper {
  margin-right: 14px;
  height: 46px;
  width: 46px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.User__info {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.User__info:hover .User__avatar-ph,
.User__info:hover .User__username
{
  color: #6188d7;
}

.User .DropdownMenu {
  top: calc(100% + 10px);
  right: -20px;
  min-width: 220px;
}

.User .DropdownMenu .User__space-info-text {
  white-space: nowrap;
  color: #272d2d;
  opacity: .75;
}

.User__space-info-text {
  margin-bottom: 15px;
}

.User__space-info-text.short {
  margin-bottom: 0;
}

.User[data-type="collapsable"] .Collapsable {
  width: 100%;
  margin-top: 10px;
  background: rgba(231, 231, 231, .15);
}

.User[data-type="collapsable"] .Collapsable > * {
  background: inherit;
}

.User[data-type="collapsable"] .User__info {
  padding: 0 30px;
}
