.Sidebar {
  width: 225px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 7px 2px 10px -5px rgba(0, 0, 0, .1);
  border-right: 1px solid #ececec;
  z-index: 22;
}

.Sidebar.changed {
  display: block;
}

.ie-browser .Sidebar {
  min-height: calc(100vh - 66px - 134px);
}

@media screen and (max-width: 1279px) {
  .Sidebar,
  .Sidebar.changed {
    display: none;
  }
  .ie-browser .Sidebar {
    min-height: 1px;
  }
}

.Sidebar__content {
  padding: 38px 0 38px 30px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 38px;
}



.Sidebar__scroll_wrap .Sidebar__content {
  margin-bottom: 0;
}

.Sidebar__scroll_wrap .Sidebar__content:last-child {
  border-bottom-width: 0;
  margin-top: -1px;
}

.Sidebar__scroll_wrap {
  border-bottom: 1px solid #ececec;
}

.Sidebar__scroll_wrap > div > div:last-child > div,
.SearchResults > div > div:last-child > div
{
  width: 2px !important;
  transition: width .3s ease, margin-left .3s ease;
  margin-left: 4px;
}

.HeaderMenu .Sidebar > div > div:last-child > div {
  width: 3px !important;
  transition: width .3s ease, margin-left .3s ease;
  margin-left: 3px;
}

.Sidebar__scroll_wrap > div > div:last-child:hover > div,
.Sidebar__scroll_wrap > div > div:last-child:active > div,
.SearchResults > div > div:last-child:hover > div,
.SearchResults > div > div:last-child:active > div,
.HeaderMenu .Sidebar > div > div:last-child:hover > div,
.HeaderMenu .Sidebar > div > div:last-child:active > div
{
  width: 5px !important;
  margin-left: 1px;
}

.User + .Sidebar__scroll_wrap {
  margin-bottom: 0;
}

.Sidebar__content.hidden {
  display: block;
}

.Sidebar__content .NavigationLink:last-of-type {
  margin-bottom: 0;
}

.Sidebar__call-to-action {
  text-align: center;
}

.Sidebar__call-to-action-logo {
  max-width: 100px;
  max-height: 40px;
  height: auto;
  margin-bottom: 10px;
}

.Sidebar__call-to-action a {
  font-size: 12px;
  width: 153px;
  display: inline-block;
  padding: 11px 0px;
  line-height: 1.2;
  vertical-align: middle;
}

.Sidebar__call-to-action-logo + a {
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 1279px) {
  .Sidebar__call-to-action a {
    display: block;
    margin: 0 auto;
  }
}

.Sidebar__footer {
  bottom: 0;
  left: 0;
  right: 0;
  justify-self: flex-end;
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 10px;
}

.Sidebar.changed .Sidebar__footer {
  position: absolute;
}

@media screen and (max-width: 1279px) {
  .Sidebar__footer {
    position: static;
  }
}

.Sidebar[data-sidebar-type="settings"] {
  background: #FCFCFC;
}

.Sidebar[data-sidebar-type="settings"] .Sidebar__content {
  border-bottom: none;
}

.Sidebar[data-sidebar-type="settings"] .Sidebar__title {
  padding-left: 20px;
  margin-top: 30px;
  font-size: 26px;
  font-weight: 300;
  color: #272d2d;
}

.Sidebar[data-sidebar-type="settings"] .NavigationLink {
  margin-bottom: 30px;
}

.Sidebar__fixed_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}