.Dashboard {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

.StatusBar_Active-upload {
  padding-top: 66px;
  display: block;
}

.StatusBar_Inactive-upload {
  display: none;
}

.Dashboard__content {
  background: rgba(231, 231, 231, .15);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.Dashboard__page {
  flex-grow: 1;
  padding: 0;
  width: calc(100% - 225px);
  position: relative;
}

.Dashboard__page > div:first-child > div:last-child {
  width: 4px !important;
}

.Dashboard__page > div > div:first-child
{
  padding: 36px 20px;
}

.Dashboard__page.withUploading > div
{
  height: calc(100vh - 132px) !important;
  margin-top: 66px;
}

@media screen and (max-width: 767px) {
  .Dashboard__page.withUploading > div
  {
    height: calc(100vh - 66px - 86px) !important;
    margin-top: 86px;
  }
}

.Static__wrapper > div {
  padding: 0 !important;
}

.Dashboard__title {
  font-size: 26px;
  letter-spacing: 0.2px;
  font-weight: 300;
  color: #272d2d;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  position: relative;
}

.limit-width.favorites {
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .Dashboard__title.limit-width {
    max-width: 75%;
  }
  .Dashboard__page > div:first-child {
    height: calc(100vh - 54px) !important;
  }
}

.Dashboard__title.Dashboard__title__with-back-link {
  padding-top: 50px;
}

.Dashboard__title.Dashboard__title__with-back-link + .Books__actions {
  margin-top: -63px;
}

.Dashboard__title .InnerLink {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  padding-left: 40px;
  padding-right: 0;
}

.Dashboard__title .InnerLink .InnerLink__arrow {
  right: unset;
  left: 0;
  transform: translateY(-95%) scale(-1, 1);
}

.Dashboard__title .capitalize {
  display: inline-block;
  margin-right: 6px;
}

.Dashboard__title .limit-width {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
  .Dashboard__title .limit-width.collection-limit {
    width: 22%;
  }
}

.Dashboard__title .Input {
  width: 245px;
}

.Books__upload.Upload__on_dashboard {
  margin-bottom: 30px;
}

.Books__upload.Upload__on_dashboard .Upload {
  position: static;
}

@media all and (max-width: 767px) {
  .Dashboard__title {
    font-size: 20px;
  }
  .Dashboard__title .capitalize {
    max-width: 100px;
    margin-right: 5px;
  }
  .Dashboard__title .capitalize +span {
    max-width: 100px;
  }
  .Dashboard__title .capitalize + .Collections__sort {
    max-width: 100%;
  }
  .Dashboard__title .InnerLink {
    font-size: 12px;
  }
  .Dashboard__title .Input {
    max-width: 145px;
  }
}

/* static pages */
.Static__wrapper {
  font-size: 14px;
  color: #272d2d;
  font-weight: 400;
  line-height: 27px;
}

.Static__wrapper .CookiesTerms__table {
  width: 100%;
  overflow-x: auto;
}
.Static__wrapper table {
  width: 100%;
  border: 1px solid black;
  margin: 15px 0;
}
.Static__wrapper th,
.Static__wrapper tr,
.Static__wrapper td
{
  border: 1px solid black;
  padding: 3px;
  text-align: center;
}

.Static__wrapper a {
  color: #00c08b;
  word-break: break-all;
}

.Static__wrapper a:hover {
  color: #272d2d;
}

.Static__wrapper p {
  margin: 0 2% 0 0;
}

.Static__wrapper section p {
  margin-top: 20px;
}

.Static__wrapper .accordion__panel p {
  margin-top: 20px;
}

.Static__wrapper .accordion__panel p:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1279px) {
  .Static__wrapper p {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .Static__wrapper {
    min-height: 400px;
  }
}

.Static__wrapper h2 {
  font-size: 24px;
  margin-top: 60px;
  margin-bottom: 20px;
}

.Static__wrapper ul {
  margin-left: 30px;
  list-style: disc;
}

.Static__wrapper h1 {
  margin: 30px 0 40px;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2;
}

.Static__wrapper span,
.Static__wrapper strong {
  font-weight: 600;
}

.accordion {
  margin: 30px 0;
}
.accordion__button {
  outline: none;
}
.accordion__button > div {
  display: inline-block;
  max-width: 90%;
  vertical-align: middle;
  margin-bottom: 3px;
}

.accordion__panel > div > h2:first-child {
  margin-top: 10px;
}

.Static__wrapper img {
  max-width: 100%;
}