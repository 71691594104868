.Header {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  height: 66px;
  border-bottom: 1px solid #ececec;
  background: rgba(231, 231, 231, .15);
  flex: none;
}

.Header__logo {
  width: 225px;
  padding: 0 21px;
  background: #fff;
  height: 103%;
  border-right: 1px solid #ececec;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, .1);
/*  border-bottom: 1px solid #ececec;*/
}

@media screen and (min-width: 1279px) {
  .Header.Header--settings-active .Header__logo {
    border-bottom: none;
    background: #fcfcfc;
  }
}

@media all and (max-width: 767px) {
  .Header {
    height: 54px;
  }
  .Header__logo {
    width: 130px;
    padding-top: 8px;
  }
}

.Header__logo img {
  max-width: 100%;
  max-height: 100%;
}

.Header__logo.christmas {
  display: flex;
  align-items: center;
}

.Header__logo.christmas img {
  max-width: 155px;
  height: auto;
  margin-top: -10px;
}

@media screen and (max-width: 767px) {
  .Header__logo.christmas img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1279px) {
  .Header__logo {
    background: transparent;
    border-bottom: none;
    border-right: none;
    box-shadow: none;
  }
  .Header__content > .User {
    display: none;
  }
  .Header__content > .Notifications {
    margin-right: 0;
  }
}

.Header__content {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  align-items: center;
  position: relative;
}

.Header.without_shadow {
  background: #fff;
}

.Header.without_shadow .Header__logo {
  box-shadow: none;
  border-right-width: 0;
  height: 100%;
  border-bottom-width: 0;
}
