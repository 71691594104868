.DropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 5px 10px 0 rgba(133, 133, 133, 0.5);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: opacity .2s ease-in-out 0s, visibility 0s .2s;
}

.ie-browser .DropdownMenu {
  display: none;
}

.DropdownMenu[data-active="true"] {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s ease-in-out 0s, visibility 0s 0s;
}

.ie-browser .DropdownMenu[data-active="true"] {
  display: block;
}

